import React from 'react';
import styled, { withTheme } from 'styled-components';
import { lighten, darken } from 'polished';
import { graphql, useStaticQuery } from 'gatsby';

import { media } from '@utils/media';
import { useTheme } from '@theme/ThemeContext';
import { backgroundColor, colors, colorModeTransition } from '@theme';

const ThemeSwitcher = ({
  theme,
  className,
  WrapperClassName,
  onAccessabilityButton,
}) => {
  const themeToggle = useTheme();
  const isDark = theme.mode === 'dark';

  const settingsData = useStaticQuery(graphql`
    {
      sanitySettings {
        enableThemeToggle
      }
    }
  `);

  return (
    <ThemeSwitcherWrapper
      className={`${WrapperClassName} ${
        settingsData &&
        settingsData.sanitySettings &&
        settingsData.sanitySettings.enableThemeToggle
          ? ''
          : 'hidden'
      }`}
      onClick={() => themeToggle.toggle()}
      type="button"
      aria-label={isDark ? 'Activate light mode' : 'Activate dark mode'}
      title={isDark ? 'Activate light mode' : 'Activate dark mode'}
    >
      <IconWrapper className={className} isDark={isDark} data-a11y="false">
        <MoonOrSun isDark={isDark} />
        <MoonMask
          isDark={isDark}
          isOnAccessabilityButton={onAccessabilityButton ? 'true' : 'false'}
        />
      </IconWrapper>
    </ThemeSwitcherWrapper>
  );
};

const ThemeSwitcherWrapper = styled.button`
  &:hover {
    /* opacity: 0.5; */
    div {
      /* border-color: #ffbf00;
        color: #ffbf00; */
      border-color: ${colors.primary};
      color: ${colors.primary};
    }
  }
`;

const IconWrapper = styled.figure`
  /* opacity: 0.5; */
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  /* margin-left: 30px; */
  &:hover {
    opacity: 1;
  }
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid currentColor !important;
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
  ${media.md`
    display: inline-flex;
    transform: scale(0.708);
    /* margin-left: 10px; */
    &:hover {
      /* opacity: 0.5; */
      div {
        /* border-color: #ffbf00;
        color: #ffbf00; */
        /* border-color: ${colors.primary};
        color: ${colors.primary}; */
      }
    }
  `}
`;

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${p => (p.isDark ? '4px' : '2px')} solid currentColor !important;
  background: ${p =>
    p.isDark
      ? darken(0.1, backgroundColor(p))
      : lighten(0.1, backgroundColor(p))};
  transform: scale(${p => (p.isDark ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${p => (p.isDark ? 'visible' : 'hidden')};
  &::before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid currentColor !important;
    border-radius: 50%;
    transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
    opacity: ${p => (p.isDark ? 0 : 1)};
    transition: transform 0.45s ease;
  }
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 currentColor, 0 23px 0 currentColor,
      23px 0 0 currentColor, -23px 0 0 currentColor, 15px 15px 0 currentColor,
      -15px 15px 0 currentColor, 15px -15px 0 currentColor,
      -15px -15px 0 currentColor;
    transform: scale(${p => (p.isDark ? 1 : 0)});
    transition: all 0.35s ease;
    ${media.md`
      transform: scale(${p => (p.isDark ? 0.92 : 0)});
    `}
  }
`;

const MoonMask = styled.div`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  /* background: ${backgroundColor}; */
  background-color: ${p => {
    if (p.isOnAccessabilityButton === 'true') {
      return p.isDark
        ? darken(0.1, backgroundColor(p))
        : lighten(0.1, backgroundColor(p));
    }
    return backgroundColor;
  }};
  transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
  opacity: ${p => (p.isDark ? 0 : 1)};
  transition: ${colorModeTransition}, transform 0.45s ease;

  &:hover {
    /* border-color: yellow; */
  }
`;

export default withTheme(ThemeSwitcher);
