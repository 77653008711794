const settings = require('../../../config/settings');

const doesModuleExist = (module) =>
  settings &&
  settings.modules &&
  settings.modules[module] &&
  settings.modules[module].activated;

const moduleSettings = (module) => {
  const settingsObject =
    settings && settings.modules && settings.modules[module];
  return { ...settingsObject };
};

exports.doesModuleExist = doesModuleExist;
exports.moduleSettings = moduleSettings;
