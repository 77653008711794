import React from 'react';
import _ from 'lodash';
import slugify from 'slugify';

import { createLocationSlug } from '@utils/locationHelpers';

// import settings from '../../../config/settings';
import {
  doesModuleExist as doesModuleExistFunc,
  moduleSettings as moduleSettingsFunc,
} from './nodeHelpers';

export const doesModuleExist = doesModuleExistFunc;
export const moduleSettings = moduleSettingsFunc;

export const isBlockActive = (block) => {
  const globalSettingsExists = !!(
    // if block exists.  This state should never exist
    (
      block &&
      // if block globalBlockSettings exists.  This state will exist if any internal settings are not null
      block.globalBlockSettings &&
      // if isActive is false.
      block.globalBlockSettings.isActive === false
    )
  );

  if (globalSettingsExists) {
    return block.globalBlockSettings.isActive;
  }
  return true;
};

export const lineBreakToBr = (text) => {
  const textWithBrs = [];
  text.split('\n').map((item, key) => {
    textWithBrs.push(
      <span key={key}>
        {item}
        <br />
      </span>
    );
    return null;
  });

  return textWithBrs;
};

// Resolve paths to pages... This will inevitable change... but not yet... not now
// export const LinkPathResolver = slugObject => {
//   if (slugObject && slugObject.__typename) {
//     if (
//       slugObject.__typename === 'SanityLocation' &&
//       slugObject.name &&
//       slugObject.slug &&
//       slugObject.slug.current &&
//       slugObject.group &&
//       slugObject.group.slug &&
//       slugObject.group.slug.current
//     ) {
//       return {
//         name: slugObject.name,
//         slug: `${slugObject.group.slug.current}/${slugObject.slug.current}`,
//       };
//     }
//   } else if (
//     slugObject &&
//     slugObject.pageInfo &&
//     slugObject.pageInfo.slug &&
//     slugObject.pageInfo.slug.current &&
//     slugObject.pageInfo.name
//   ) {
//     return {
//       name: slugObject.pageInfo.name,
//       slug: slugObject.pageInfo.slug.current,
//     };
//   }

//   return null;
// };

const returnLinkAttributes = ({
  anchor,
  text,
  href,
  title,
  key,
  type,
  openNew,
  slugType,
  productInfo,
}) => ({
  // This function acts as a middleware to groom and normalize the data if need be
  anchor: `${anchor && `#${anchor}`}`,
  text,
  href,
  title,
  key: key || Math.floor(Math.random() * 1000000) + 1, // if no ket is set, return random value
  type,
  openNew: openNew || null,
  slugType,
  productInfo,
});

export const linkResolver = (linkObjectArg) => {
  // if link
  if (linkObjectArg && linkObjectArg.link && linkObjectArg.link[0]) {
    const linkObject = linkObjectArg.link[0];

    // if link has text and link obj and link obj has type and that a slug exists
    if (linkObject && linkObject._type) {
      const linkText = linkObjectArg.text || '';
      const anchorText =
        (linkObjectArg.anchor && linkObjectArg.anchor.current) || '';

      const linkType = linkObject._type;

      // if link is internal
      if (
        linkType === 'linkInternal' &&
        linkObject.slug &&
        linkObject.slug.pageInfo &&
        linkObject.slug.pageInfo.slug &&
        linkObject.slug.pageInfo.slug.current
      ) {
        let customSlug = null;
        // special routes here
        if (linkObject.slug._type === 'location') {
          customSlug = createLocationSlug(linkObject.slug);
        }

        // return
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: `/${
            customSlug ||
            (linkObject.slug.pageInfo.slug.current === '/'
              ? ''
              : linkObject.slug.pageInfo.slug.current)
          }`,
          title: linkObjectArg.title || linkObject.slug.pageInfo.name || '',
          key: linkObject.id && linkObject.id,
          type: 'internal',
          slugType: linkObject.slug._type,
          productInfo: {
            price:
              linkObject.slug._type === 'product' &&
              linkObject.slug.price &&
              linkObject.slug.price,
            sku:
              linkObject.slug._type === 'product' &&
              linkObject.slug.sku &&
              linkObject.slug.sku,
            name:
              linkObject.slug._type === 'product' &&
              linkObject.slug.pageInfo.name &&
              linkObject.slug.pageInfo.name,
            slug:
              linkObject.slug._type === 'product' &&
              linkObject.slug.pageInfo.slug.current &&
              linkObject.slug.pageInfo.slug.current,
            description:
              linkObject.slug._type === 'product' &&
              linkObject.slug.descriptionCart &&
              linkObject.slug.descriptionCart,
            image:
              linkObject.slug._type === 'product' &&
              linkObject.slug.image.asset.url &&
              linkObject.slug.image.asset.url,
          },
        });
      }

      // if link is external
      if (linkType === 'linkExternal' && linkObject.url) {
        return returnLinkAttributes({
          anchor: anchorText,
          text: linkText,
          href: linkObject.url,
          title: linkObjectArg.title || linkText || '',
          key: linkObject._key && linkObject._key,
          type: 'external',
          openNew: linkObject.openNew && linkObject.openNew,
          slugType: 'external',
        });
      }
    }
  }

  return null;
};

// Returns link type
export const returnLinkType = (linkObject) =>
  linkObject && linkObject && linkObject._type ? linkObject._type : null;

// If link is internal, return true
export const isInternal = (linkObject) =>
  returnLinkType(linkObject) === 'linkInternal';

// If link is external, return true
export const isExternal = (linkObject) =>
  returnLinkType(linkObject) === 'linkExternal';

export const hasImage = (image) =>
  !!(
    image &&
    image.asset &&
    (image.asset.fluid || image.asset.fixed || image.asset.url)
  );

export const determineColumnWith = (maxNumColumns, numOfItems) => {
  // Coming soon?
  // const numOfItems = (props.data.staffMembers.length);
  //     const maxItemsInRow = 3
  //     const numOfRows = Math.ceil(numOfItems / maxItemsInRow);
  //     const itemsInEachRow = Math.ceil(numOfItems / numOfRows);
  //     const columnWidth =  12 / itemsInEachRow ;
  //     const potentialMax = maxItemsInRow * numOfRows;
  //     const remainder = (itemsInEachRow - (potentialMax - numOfItems));
  //     const lastRowColumnWidth =  12 / (Math.ceil(remainder));
  //     const startLastRow = ((numOfRows - 1) * itemsInEachRow);
  //     const fillLastRow  = false;
};

export const evenOrOdd = (num) => (num % 2 === 1 ? 'odd' : 'even');

export const slugifyModified = (slug, additionalOptions = {}) => {
  const options = {
    replacement: '-', // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
  };

  const mergedOptions = _.merge(options, additionalOptions);

  return slugify(slug, mergedOptions);
};

// Use the NPM package 'pluralize' unless you need this one.
export const pluralize = (
  data,
  wordSansEnding,
  singularEnding,
  pluralEnding
) => {
  if (data.length) {
    return `${wordSansEnding}${
      data.length < 2 ? singularEnding : pluralEnding
    }`;
  }
  return `${wordSansEnding}${singularEnding}`;
};

export const simpleFormBuilder = (field, keyPreFormat) => {
  // console.log('field.type:', field.type);
  if (field && field.question && field.type) {
    // Field must have question and type
    let returnField;
    const today = new Date();
    const width = 3;
    const key = (new Array(width).join('0') + keyPreFormat).substr(-width);
    const slugifiedQuestion = slugifyModified(field.question);

    const date = `${today.getFullYear()}-${`0${today.getMonth() + 1}`.slice(
      -2
    )}-${today.getDate()}`;

    if (field.type === 'heading') {
      returnField = (
        <div key={key} className="sm:col-span-6 mt-6">
          <div>
            <h3 className="">{field.question}</h3>
          </div>
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2 hidden">
            <span className="block font-medium leading-5">
              Section: {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifyModified(field.question)}
                value="_________________"
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'agreement') {
      returnField = (
        <div key={key} className="sm:col-span-6">
          <p>
            I understand that I may be asked to do certain “homework exercises”
            such as reading, praying, changing behaviors, and otherwise acting
            in my own best interest. I understand that I am entirely responsible
            for my own actions and I will always make my own final decisions
            regarding counseling.
          </p>
          <p>
            I further understand that much of the work done will be to resolve
            issues and will depend on my honesty, and willingness to do the
            things I need to do to move forward even if it is painful and
            difficult.
          </p>
          <p>
            I understand that whatever I say in a session is strictly
            confidential and will not be released to anyone without my consent
            unless I am violating codes of abuse, harm to myself or others.
          </p>

          <p>
            I understand that I will pay in full for appointments not canceled
            with 24 hours’ notice.
          </p>
          <p>
            As your therapist/counselor, you honor me by sharing your life and
            growth with me. I will not hide myself behind silence or position
            and will have high regard for you as a person. I will bring the best
            that I know from my study and experience. I will bring you the
            highest of my insight, wisdom, and spiritual guidance. I will keep a
            holistic perspective in our work together because I believe that the
            Physical, Spiritual, and Soul (mind, will, emotions) all work
            together to form the wholly healthy person.
          </p>
          <p>
            You can expect truth from me even when you may not want to hear it.
            I will always have compassion and empathy for you in all that we do.
            I value you as a person in need of care. I will do my best to honor
            that.
          </p>
          <p>
            <strong>Dr. John E. N. Daniel</strong>
          </p>
        </div>
      );

      return returnField;
    }
    if (field.type === 'text') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifiedQuestion}
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'textarea') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifiedQuestion}
                className="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                rows="3"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'email') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label
            htmlFor={slugifiedQuestion}
            className={`sm:col-span-2 ${field.labelClass && field.labelClass}`}
          >
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifiedQuestion}
                type="email"
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'tel') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifiedQuestion}
                type="tel"
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'date') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                required={!!field.required}
                id={slugifiedQuestion}
                name={slugifiedQuestion}
                type="date"
                defaultValue={date}
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              />
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'radio' && field.options && field.options.length > 0) {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <fieldset className="sm:col-span-2">
            <legend className="block font-medium leading-5">
              {field.question}
            </legend>

            <div className="mt-4 flex items-center space-x-4">
              {field.options.map((option, i) => (
                <label key={i} className="flex items-center">
                  <input
                    value={option}
                    name={slugifiedQuestion}
                    type="radio"
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <span className="block ml-3 text-sm leading-5 font-medium text-gray-700">
                    {option}
                  </span>
                </label>
              ))}
            </div>
          </fieldset>
        </div>
      );

      return returnField;
    }
    if (
      field.type === 'checkbox' &&
      field.options &&
      field.options.length > 0
    ) {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <fieldset className="sm:col-span-2">
            <legend className="block font-medium leading-5">
              {field.question}
            </legend>

            <div className="mt-4 flex items-center space-x-4">
              {field.options.map((option, i) => (
                <label key={i} className="flex items-center">
                  <input
                    required={!!field.required}
                    id={slugifyModified(option)}
                    name={slugifiedQuestion}
                    type="checkbox"
                    value={option}
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <span className="block ml-3 text-sm leading-5 font-medium text-gray-700">
                    {option}
                  </span>
                </label>
              ))}
            </div>
          </fieldset>
        </div>
      );

      return returnField;
    }
    if (field.type === 'select' && field.options && field.options.length > 0) {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <select
                required={!!field.required}
                id={slugifiedQuestion}
                className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                name="country"
              >
                {field.options.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'country') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>

            <select
              required={!!field.required}
              id={slugifiedQuestion}
              className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 mt-1 relative rounded-md shadow-sm"
              name={`${slugifiedQuestion}`}
              defaultValue="United States"
            >
              <option value="Barbados">Barbados</option>
              <option value="Canada">Canada</option>
              <option value="United States">United States</option>
              <option value="Other">Other</option>
            </select>
          </label>
        </div>
      );

      return returnField;
    }
    if (field.type === 'state') {
      returnField = (
        <div
          key={key}
          className={`${field.fullWidth ? 'sm:col-span-6' : 'sm:col-span-3'}`}
        >
          <label htmlFor={slugifiedQuestion} className="sm:col-span-2">
            <span className="block font-medium leading-5">
              {field.question}
            </span>

            <select
              required={!!field.required}
              id={slugifiedQuestion}
              className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 mt-1 relative rounded-md shadow-sm"
              name={`${slugifiedQuestion}`}
              defaultValue="FL"
            >
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="California">California</option>
              <option value="Colorado">Colorado</option>
              <option value="Connecticut">Connecticut</option>
              <option value="Delaware">Delaware</option>
              <option value="District Of Columbia">District Of Columbia</option>
              <option value="Florida">Florida</option>
              <option value="Georgia">Georgia</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="Indiana">Indiana</option>
              <option value="Iowa">Iowa</option>
              <option value="Kansas">Kansas</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Maine">Maine</option>
              <option value="Maryland">Maryland</option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Mississippi">Mississippi</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Nevada">Nevada</option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York">New York</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oregon">Oregon</option>
              <option value="Pennsylvania">Pennsylvania</option>
              <option value="Rhode Island">Rhode Island</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee">Tennessee</option>
              <option value="Texas">Texas</option>
              <option value="Utah">Utah</option>
              <option value="Vermont">Vermont</option>
              <option value="Virginia">Virginia</option>
              <option value="Washington">Washington</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin">Wisconsin</option>
              <option value="Wyoming">Wyoming</option>
            </select>
          </label>
        </div>
      );

      return returnField;
    }
  }
};
