import React from 'react';
import tw from 'tailwind.macro';
import { createGlobalStyle, css } from 'styled-components';
import { rgba, darken, lighten } from 'polished';

import Header from '@components/Header/Header';
import Footer from '@components/Footer';
import Accessability from '@components/Accessability';

import { Helmet } from 'react-helmet';
import { colors } from '../theme';

const darkStyles = css`
  background-color: ${colors.gray9};
  color: ${rgba(colors.gray1, 0.8)};

  .color-mode-background {
    background-color: ${colors.gray9};
  }
  .color-mode-background-opposite {
    background-color: ${colors.gray1};
  }

  .color-mode-color {
    color: ${colors.gray1};
  }
  .color-mode-color-opposite {
    color: ${colors.gray9};
  }

  .color-mode-color-fill {
    fill: ${colors.gray9};
  }
  .color-mode-color-fill-opposite {
    fill: ${colors.gray1};
  }

  .color-mode-color-stroke {
    stroke: ${colors.gray9};
  }
  .color-mode-color-stroke-opposite {
    stroke: ${colors.gray1};
  }

  .color-mode-background-200 {
    background-color: ${colors.gray8};
  }

  .hasSVG svg, .hasSVG svg path {
    fill: ${colors.gray9} !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* ${tw`font-secondary`}; */
    color: ${colors.gray1};
  }

  div {
    color: ${rgba(colors.gray1, 0.8)};
  }

  .btn {
    background-color: ${colors.gray1};
    border-color: ${colors.gray1};
    color: ${colors.gray9};

    &:hover {
      background-color: ${colors.primary};
      border-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  .btn-primary {
    &:hover {
      color: ${colors.gray9} !important;
      background-color: ${colors.gray1} !important;
      border-color: ${colors.gray1} !important;
    }
  }

  .btn-ghost {
    color: ${colors.gray1};
    background-color: transparent;

    &:hover {
      color: ${colors.primary};
      background-color: transparent;
    }

    &:focus {
      background-color: ${darken(0.1, colors.gray9)};
    }
  }

  * {
    border-color: ${rgba(
      colors.gray1,
      0.1
    )}; /* This is likely a bad idea... but lets see what issues arise */
  }
`;

const lightStyles = css`
  background-color: ${colors.gray1};
  color: ${rgba(colors.gray9, 0.8)};

  .color-mode-background {
    background-color: ${colors.gray1};
  }
  .color-mode-background-opposite {
    background-color: ${colors.gray9};
  }

  .color-mode-color {
    color: ${colors.gray9};
  }
  .color-mode-color-opposite {
    color: ${colors.gray1};
  }

  .color-mode-color-fill {
    fill: ${colors.gray1};
  }
  .color-mode-color-fill-opposite {
    fill: ${colors.gray9};
  }

  .color-mode-color-stroke {
    stroke: ${colors.gray1};
  }
  .color-mode-color-stroke-opposite {
    stroke: ${colors.gray9};
  }

  .color-mode-background-200 {
    background-color: ${colors.gray2};
  }

  .hasSVG svg, .hasSVG svg path {
    fill: ${colors.gray1} !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* ${tw`font-secondary`}; */
    color: ${colors.gray9};
  }

  div {
    color: ${rgba(colors.gray9, 0.8)};
  }

  .btn {
    background-color: ${colors.gray9};
    border-color: ${colors.gray9};
    color: ${colors.gray1};

    &:hover {
      background-color: ${colors.primary};
      border-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  .btn-primary {
    &:hover {
      color: ${colors.gray1} !important;
      background-color: ${colors.gray9} !important;
      border-color: ${colors.gray9} !important;
    }
  }

  .btn-ghost {
    color: ${colors.gray9};
    background-color: transparent;

    &:hover {
      color: ${colors.primary};
      background-color: transparent;
    }

    &:focus {
      background-color: ${lighten(0.1, colors.gray1)};
    }
  }

  * {
    border-color: ${rgba(
      colors.gray9,
      0.1
    )}; /* This is likely a bad idea... but lets see what issues arise */
  }
`;

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${rgba(colors.primary, 1)}; /* WebKit/Blink Browsers */
    color: ${colors.white};
  }
  ::-moz-selection {
    background: ${rgba(colors.primary, 1)}; /* Gecko Browsers */
    color: ${colors.white};
  }

  /* .btn.btn-ghost {

    &:hover {
      border-color: ${colors.primary};
      color: ${colors.primary};
    }
  } */

  .color-mode-light {
    ${lightStyles}

    .color-mode-dark {
      ${darkStyles}
    }
  }
  
  .color-mode-dark {
    ${darkStyles}

    .color-mode-light {
      ${lightStyles}
    }
  }

  a:hover {
    color: ${colors.primary};
  }

  .svgLight svg, .svgLight svg path {
    fill: ${colors.gray1};
  }

`;

const Layout = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (hasMounted) {
    const color = localStorage.getItem('color-mode');
    const list = document.querySelectorAll('.color-mode-toggle');
    list.forEach((element) => {
      element.classList.add(
        color === 'dark' ? 'color-mode-light' : 'color-mode-dark'
      );
      element.classList.remove(
        color === 'dark' ? 'color-mode-dark' : 'color-mode-light'
      );
    });
  }

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="antialiased">
        <GlobalStyle />
        <Header />
        <>{children}</>
        <Footer />
        <Accessability />
      </div>
    </>
  );
};

export default Layout;
