import React from 'react';

import InfoBar from '@components/Header/InfoBar';
import Nav from '@components/Header/Nav';

const Header = () => (
  <>
    <InfoBar />
    <Nav />
  </>
);

export default Header;
