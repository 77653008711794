import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import tw from 'tailwind.macro';
import { darken, lighten } from 'polished';

import { backgroundColor } from '@theme';
import ThemeSwitcher from '@components/ThemeSwitcher';

const Accessability = props => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <AccessabilityWrap
      themeContext={themeContext.mode}
      className="shadow-lg border border-r-0 border-b-0 fixed bottom-0 right-0 rounded-tl-lg z-50 overflow-hidden"
      backgroundColor={backgroundColor}
    >
      <ThemeSwitcher
        WrapperClassName="w-16 h-16 flex items-center justify-center cursor-pointer"
        className=""
        onAccessabilityButton
      />
    </AccessabilityWrap>
  );
};

export default Accessability;

const AccessabilityWrap = styled.div`
/* ${tw``}; */
  background-color: ${p =>
    p.themeContext === 'dark'
      ? darken(0.1, backgroundColor(p))
      : lighten(0.1, backgroundColor(p))} ;
`;
