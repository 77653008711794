// Location helpers

const returnGroupSlug = location => {
  // Returns urls group name or "location"
  if (
    location.group &&
    location.group.pageInfo &&
    location.group.pageInfo.slug &&
    location.group.pageInfo.slug.current
  ) {
    return location.group.pageInfo.slug.current;
  }

  return 'location';
};

export const createLocationSlug = location =>
  `${returnGroupSlug(location)}/${location.pageInfo.slug.current}`; // create location url
