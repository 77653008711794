import React from 'react';

import { Button } from '@elements';

export const ButtonGroup = ({
  buttons,
  backgroundColor,
  hasImageAsBackground,
  className,
  orientation,
  stack,
  buttonClasses,
}) => {
  let align = !stack ? 'justify-center' : 'items-center';

  if (orientation) {
    if (orientation === 'left') {
      if (stack) {
        align = 'items-start';
      } else {
        align = 'justify-start';
      }
    }
    if (orientation === 'right') {
      if (stack) {
        align = 'items-end';
      } else {
        align = 'justify-end';
      }
    }
  }
  if (buttons) {
    return (
      <div
        className={`mt-8 flex flex-col ${
          !stack && 'items-center md:flex-row md:items-stretch'
        } mb-6 
        ${align} ${className}`}
      >
        {buttons &&
          buttons.map((button, i) => {
            let additionalButtonClasses;
            let buttonGhostClasses;

            if (hasImageAsBackground) {
              additionalButtonClasses = ``;
              buttonGhostClasses = ``;
            } else if (
              backgroundColor === 'primary' ||
              backgroundColor === 'secondary'
            ) {
              additionalButtonClasses = `hover:bg-gray-900 hover:border-gray-900`;
              buttonGhostClasses = `hover:border-white hover:bg-white hover:text-gray-900`;
            } else {
              additionalButtonClasses = ``;
              buttonGhostClasses = ``;
            }

            return (
              <Button
                key={button._key}
                buttonObject={button}
                className={`w-3/4 ${!stack ? 'md:w-auto' : 'md:w-3/4'} ${
                  i > 0 && `mt-3 ${!stack && 'md:mt-0 md:ml-3'}`
                } ${
                  button.isGhost === true
                    ? buttonGhostClasses
                    : additionalButtonClasses
                } ${buttonClasses}`}
              />
            );
          })}
      </div>
    );
  }
  return null;
};
