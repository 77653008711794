import React from 'react';
import { graphql } from 'gatsby';

import { Link } from '@elements';

export const Button = ({ buttonObject, className }) => {
  if (buttonObject && buttonObject.link && buttonObject.link.link && buttonObject.link.link[0]) {
    return (
      <Link
        key={
          buttonObject.link.link &&
          buttonObject.link.link[0].key &&
          buttonObject.link.link[0].key
        }
        link={buttonObject.link}
        className={`btn ${buttonObject.isGhost && 'btn-ghost'} ${
          buttonObject.color && buttonObject.isGhost !== true
            ? `btn-${buttonObject.color}`
            : ''
        } ${className}`}
      />
    );
  }
  return null;
};

export const ButtonFragment = graphql`
  fragment ButtonFragment on SanityButton {
    _key
    color
    isGhost
    link {
      ...LinkFragment
    }
  }
`;
