// These are Module configurations... As of right now there are no rules and Ken Jones is the king of the castle... If you are reading this message, then nothing has changed

module.exports = {
  siteTitle: 'Leadership Empowerment and Psychological Services Inc.',
  shortname: 'L.E.A.P.S!',
  path_prefix: '/',
  backgroundColor: '#ffffff',
  themeColor: '#a04173',
  siteUrl: 'http://leapsinc.com',
  siteDescription: 'Transforming and Enriching the Quality of Your Life',
  googleAnalyticsID: 'UA-173997146-1',
  modules: {
    homepage: {
      activated: true,
    },
    pages: {
      activated: true,
    },
    locations: {
      activated: false,
    },
    regions: {
      activated: false,
    },
    blog: {
      activated: true,
      renderTagPages: true,
      renderCategoryPages: true,
      renderAuthorPages: true,
      postsPerPage: 12,
      baseName: 'blog',
    },
  },
};
