const defaultColors = require('gatsby-theme-seagull/src/theme/colors');

const colors = {
  colors: {
    ...defaultColors.colors,
    // primary: '#13BCE1',
  },
};

exports.colors = colors;
