import theme from 'styled-theming';

import { colors as colorsImported } from './colors';

export const { colors } = colorsImported;

export const transitionTime = `0.2s`;

// const colors = {
//   light: {
//     background: '#ECEFF4',
//     cardBackground: '#E5E9F0',
//     buttonBackground: '#5E81AC',
//     textColor: '#2E3440',
//     buttonColor: '#ECEFF4',
//   },
//   dark: {
//     background: '#2E3440',
//     cardBackground: '#3B4252',
//     buttonBackground: '#d08770',
//     textColor: '#ECEFF4',
//     buttonColor: '#2E3440',
//   },
// };

export const backgroundColor = theme('mode', {
  light: colors.gray1,
  dark: colors.gray9,
});

export const themeName = theme('mode', {
  light: 'light',
  dark: 'dark',
});

export const textColor = theme('mode', {
  light: colors.gray9,
  dark: colors.gray1,
});

export const primaryButtonBG = theme('mode', {
  light: colors.primary,
  dark: colors.primary,
});

export const primaryButtonTC = theme('mode', {
  light: colors.white,
  dark: colors.white,
});

export const secondaryButtonBG = theme('mode', {
  light: colors.secondary,
  dark: colors.secondary,
});

export const secondaryButtonTC = theme('mode', {
  light: colors.white,
  dark: colors.white,
});

export const border100 = theme('mode', {
  light: colors.gray2,
  dark: colors.gray6,
});

export const screens = {
  sm: '640',
  md: '768',
  lg: '1024',
  xl: '1280',
};

export const transition = `transition: all ${transitionTime} ease`;

export const colorModeTransition =
  'background 2s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)';

// export const primaryColor = theme('mode', {
//   light: colors.light.primary,
//   dark: colors.dark.primary,
// });

// // import colors from './colors';

// export const lightTheme = {
//   mode: 'light',
//   colors: colors.light,
// };

// export const darkTheme = {
//   mode: 'dark',
//   colors: colors.dark,
// };

// //////////////////////////
// import merge from 'lodash/merge';

// import colors from './colors';
// import styles from './styles';

// const breakpoints = [
//   ['phone_small', 320],
//   ['phone', 376],
//   ['phablet', 540],
//   ['tablet', 735],
//   ['desktop', 1070],
//   ['desktop_medium', 1280],
//   ['desktop_large', 1440],
// ];

// const fonts = {
//   body: 'system-ui, sans-serif',
//   monospace: 'Menlo, monospace',
// };

// const fontSizes = [
//   12, 14, 16, 20, 24, 32, 48, 64
// ];

// const fontWeights = {
//   body: 400,
//   heading: 700,
//   bold: 700,
// };

// const lineHeights = {
//   body: 1.5,
//   heading: 1.125,
// };

// const letterSpacings = {
//   body: 'normal',
//   caps: '0.2em',
// };

// const space = [12, 14, 16, 20, 24, 32, 48, 64, 96]

// const colorModeTransition =
//   'background 2s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)';

// export default merge({
//   initialColorModeName: 'light',
//   useColorSchemeMediaQuery: true,
//   useCustomProperties: true,
//   colorModeTransition,
//   colors,
//   fonts,
//   fontSizes,
//   fontWeights,
//   lineHeights,
//   letterSpacings,
//   breakpoints,
//   space,
//   styles,
// });
